import { PortableText } from '@portabletext/react';
import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import subtitleBackground from '../../../images/textAndImage_subtitle.png';
import { components } from '../../../portableText';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { BackgroundColorTheme } from '../../../templates/Page';
import { RawPortableText } from '../../../types/types';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './TextAndImageModule.module.scss';

export const TextAndImageModuleFragment = graphql`
  fragment TextAndImageModule on SanityGtgvBookTextAndImageModule {
    title
    _rawText(resolveReferences: { maxDepth: 6 })
    image {
      ...SanityImage
    }
    imageSubtitle
    showClientBrands
    clientsBrandsTitle
  }
`;

export type TextAndImageModuleProps = {
  title: string;
  _rawText: RawPortableText;
  image: SanityImageType;
  imageSubtitle?: string;
  showClientBrands?: boolean;
  clientsBrandsTitle?: string;
  className?: string;
};

interface QueryData {
  allSanityClientBrand: {
    nodes: Array<{
      darkLogo: SanityImageType;
      whiteLogo: SanityImageType;
      title: string;
    }>;
  };
}

export function getModuleBgColor(props: TextAndImageModuleProps): BackgroundColorTheme {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'white';
}

function TextAndImageModule(
  props: TextAndImageModuleProps & CommonModuleProps,
): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityClientBrand(sort: { orderRank: ASC }) {
        nodes {
          title
          darkLogo {
            ...SanityImage
          }
          whiteLogo {
            ...SanityImage
          }
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityClientBrand": {
      "nodes": *[_type == "clientBrand"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { nodes: clientBrands } = data.allSanityClientBrand;

  const {
    title,
    _rawText,
    image,
    imageSubtitle,
    showClientBrands,
    clientsBrandsTitle,
    className,
    moduleId,
  } = props;

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(className, styles.container)}
      currentModuleBgColor={getModuleBgColor(props)}
    >
      <div className={clsx(styles.contentContainer)}>
        <div className={styles.mediaContainer}>
          <div className={clsx(styles.imageContainer)}>
            <Image image={image} className={styles.image} />
            {imageSubtitle && (
              <div className={styles.imageSubtitleContainer}>
                <img src={subtitleBackground} className={styles.imageSubtitleBackground} alt="" />
                <span className={styles.imageSubtitle}>{imageSubtitle}</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.textContainer}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <div className={styles.text}>
            <PortableText value={_rawText} components={components} />
          </div>
          {showClientBrands && clientsBrandsTitle && (
            <div className={styles.clientsBrandsContainer}>
              <h3 className={styles.clientBrandsTitle}>{clientsBrandsTitle}</h3>
              <div className={styles.clientsLogosContainer}>
                {clientBrands.map((brand, i) => (
                  <div className={styles.clientBrandContainer} key={i}>
                    <Image
                      image={brand.darkLogo}
                      title={brand.title}
                      className={styles.clientLogo}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </ModuleLayout>
  );
}

export default TextAndImageModule;
