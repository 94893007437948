import React from 'react';

import logo from '../../images/hookPointLogo.svg';
import Layout from './Layout';

interface SanityLayoutProps {
  children: React.ReactNode;
}

const SanityLayout = ({ children }: SanityLayoutProps): React.ReactElement => {
  return (
    <Layout
      pageTheme={'white'}
      headerConfig={{
        sticky: false,
        logoImage: logo,
        theme: 'white',
      }}
      footerConfig={{
        logoImage: logo,
        theme: 'white',
      }}
    >
      {children}
    </Layout>
  );
};

export default SanityLayout;
