// extracted by mini-css-extract-plugin
export var bookContainer = "BooksModule-module--bookContainer--6845d";
export var bookDescription = "BooksModule-module--bookDescription--39973";
export var bookSubtitle = "BooksModule-module--bookSubtitle--9c006";
export var bookTextContainer = "BooksModule-module--bookTextContainer--a65ca";
export var bookTitle = "BooksModule-module--bookTitle--2865f";
export var booksContainer = "BooksModule-module--booksContainer--ba3cc";
export var desktop = "BooksModule-module--desktop--2d539";
export var image = "BooksModule-module--image--ad951";
export var imageContainer = "BooksModule-module--imageContainer--16864";
export var mobile = "BooksModule-module--mobile--f94d5";
export var stamp = "BooksModule-module--stamp--a39b3";
export var stampContainer = "BooksModule-module--stampContainer--930f1";