import 'react-multi-carousel/lib/styles.css';

import { graphql } from 'gatsby';
import React, { useState } from 'react';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactCarousel from 'react-multi-carousel';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { BackgroundColorTheme } from '../../../templates/Page';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import VideoTestimonialCard from '../VideoTestimonialCard';
import * as styles from './VideoTestimonialsModule.module.scss';

export const VideoTestimonialsModuleFragment = graphql`
  fragment VideoTestimonialsModule on SanityGtgvBookVideoTestimonialsModule {
    title
    videoTestimonials {
      name
      text
      image {
        ...SanityImage
      }
      videoUrl
    }
  }
`;

export interface VideoTestimonialsModuleProps {
  title: string;
  videoTestimonials: Array<{
    name: string;
    text: string;
    image: SanityImageType;
    videoUrl: string;
  }>;
  className?: string;
}

const ButtonGroup = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
}: {
  next: () => void;
  previous: () => void;
  carouselState: { currentSlide: number; totalItems: number; slidesToShow: number };
}) => {
  return (
    <div className={clsx(styles.buttonGroup, totalItems < 2 && styles.noArrows)}>
      <button
        disabled={currentSlide === 0}
        className={clsx(styles.button, styles.buttonLeft)}
        onClick={() => {
          previous();
        }}
      >
        <FaChevronLeft className={styles.buttonIcon} />
        <div className={styles.visuallyHidden}>Previous</div>
      </button>

      <button
        disabled={currentSlide === totalItems - slidesToShow}
        className={clsx(styles.button, styles.buttonRight)}
        onClick={() => {
          next();
        }}
      >
        <FaChevronRight className={styles.buttonIcon} />
        <div className={styles.visuallyHidden}>Next</div>
      </button>
    </div>
  );
};

export function getModuleBgColor(props: VideoTestimonialsModuleProps): BackgroundColorTheme {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'white';
}

function VideoTestimonialsModule(
  props: VideoTestimonialsModuleProps & CommonModuleProps,
): React.ReactElement {
  const { title, videoTestimonials, className, moduleId, previousModuleBgColor } = props;

  // const items = [
  //   ...videoTestimonials,
  //   ...videoTestimonials,
  //   ...videoTestimonials,
  //   ...videoTestimonials,
  //   ...videoTestimonials,
  //   ...videoTestimonials,
  //   ...videoTestimonials,
  // ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleBeforeChange = (nextSlide: number) => {
    setCurrentSlide(nextSlide);
  };

  const responsive = {
    mobile: {
      breakpoint: { min: 0, max: 720 },
      items: 1,
    },
    tablet: {
      breakpoint: { min: 720, max: 1200 },
      items: 2,
    },
    laptop: {
      breakpoint: { min: 1200, max: 3000 },
      items: 3,
    },
  };

  const slidesToShow = responsive.laptop.items;
  const middleIndex = Math.floor(slidesToShow / 2);

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
      childrenClassName={styles.contentContainer}
      centeredTitle
    >
      <div className={styles.carouselContainer}>
        <ReactCarousel
          arrows={false}
          draggable={true}
          responsive={responsive}
          ssr={true}
          className={styles.carousel}
          containerClass={clsx(
            className,
            styles.carousel,
            videoTestimonials.length < 2 && styles.noArrows,
          )}
          customButtonGroup={
            //@ts-expect-error
            <ButtonGroup
              carouselState={{ currentSlide, totalItems: videoTestimonials.length, slidesToShow }}
            />
          }
          renderButtonGroupOutside
          partialVisible
          showDots={true}
          shouldResetAutoplay={false}
          pauseOnHover
          beforeChange={handleBeforeChange}
        >
          {videoTestimonials.map((testimonial, i) => (
            <React.Fragment key={i}>
              <VideoTestimonialCard
                index={i}
                {...testimonial}
                isActive={i === currentSlide + middleIndex}
              />
            </React.Fragment>
          ))}
        </ReactCarousel>
      </div>
    </ModuleLayout>
  );
}

export default VideoTestimonialsModule;
