// extracted by mini-css-extract-plugin
export var ctaButton = "Header-module--ctaButton--f24cb";
export var desktopMenu = "Header-module--desktopMenu--fc551";
export var dropDownMenuWrapper = "Header-module--dropDownMenuWrapper--25090";
export var dropDownMenuWrapperWindow = "Header-module--dropDownMenuWrapperWindow--4eb60";
export var header = "Header-module--header--3c27c";
export var headerContainer = "Header-module--headerContainer--28a42";
export var headerWithMenuLarge = "Header-module--headerWithMenuLarge--d118f";
export var headerWithMenuMedium = "Header-module--headerWithMenuMedium--a90cc";
export var headerWithMenuSmall = "Header-module--headerWithMenuSmall--174de";
export var html = "Header-module--html--aa309";
export var linksContainer = "Header-module--linksContainer--a513c";
export var logoContainer = "Header-module--logoContainer--ca573";
export var logoWrapper = "Header-module--logoWrapper--0a231";
export var menuButton = "Header-module--menuButton--a8d77";
export var menuOpen = "Header-module--menuOpen--7b2aa";
export var stickyHeader = "Header-module--stickyHeader--1dd85";
export var visuallyHidden = "Header-module--visuallyHidden--c08f3";