/* eslint-disable @typescript-eslint/no-unsafe-return */
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import groq from 'groq';
import { ALL_LANGS, SITE_BASE_URL } from '../constants';
import { LocationContext } from '../contexts/LocationContext';
import { LocalizedSEO, LocalizedSEOWebsite } from '../graphql-fragments/seo';
import { usePreviewData } from '../preview/previewUtils';
import { getPageDocumentUrl, localize } from '../utils/sanity';
import { urlJoin } from '../utils/utils';
import SEO from './SEO';

interface LocalizedQueryData {
  site: {
    siteMetadata: {
      author: string;
    };
  };
  sanityGtgvBookLandingPage: {
    seo: LocalizedSEOWebsite;
  };
}

interface PageSEOProps {
  defaultTitle: string;
  defaultDescription?: string;
  defaultImageUrl?: string;
  pageSEO?: LocalizedSEO;
}

function PageSEO({
  defaultTitle,
  defaultDescription,
  defaultImageUrl,
  pageSEO,
}: PageSEOProps): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const location = useContext(LocationContext)!;
  const { i18n } = useTranslation();

  const websiteSeoStaticData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
        }
      }
      sanityGtgvBookLandingPage {
        seo {
          ...SEOWebsite
        }
      }
    }
  `);

  const groqQuery = groq`{
    "sanityGtgvBook": *[_type == "gtgvBook"][0] {
      seo {
        ...
      }
    }
  }`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const websiteSeoData = usePreviewData<any>(websiteSeoStaticData, {
    groqQuery,
    preprocessPreviewData: previewData => ({
      ...previewData,
      site: websiteSeoStaticData.site,
    }),
  });

  if (!websiteSeoData) {
    return <></>;
  }

  const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
  const {
    site: {
      siteMetadata: { author },
    },
    sanityGtgvBookLandingPage: { seo: websiteSEO },
  } = localize(websiteSeoData, langs) as LocalizedQueryData;
  const pageTitle = pageSEO?.title || defaultTitle;
  const pageShareDescription = pageSEO?.shareDescription || pageSEO?.description;
  const websiteTitle = websiteSEO.title;
  const websiteShareTitle = websiteSEO.shareTitle || websiteTitle;
  const websiteShareDescription = websiteSEO.shareDescription || websiteSEO.description;
  return (
    <SEO
      title={pageTitle}
      shareTitle={
        pageSEO?.shareTitle ||
        (pageTitle ? pageTitle + ' - ' + websiteShareTitle : websiteShareTitle)
      }
      description={pageSEO?.description || defaultDescription || websiteSEO.description}
      shareDescription={pageShareDescription || websiteShareDescription}
      url={urlJoin(SITE_BASE_URL, location.pathname)}
      canonicalUrl={
        pageSEO?.canonicalPage
          ? urlJoin(SITE_BASE_URL, getPageDocumentUrl(pageSEO.canonicalPage))
          : urlJoin(SITE_BASE_URL, location.pathname)
      }
      imageUrl={pageSEO?.image?.asset?.url || defaultImageUrl || websiteSEO.image?.asset?.url}
      lang={i18n.language}
      author={author}
      noFollow={pageSEO?.noFollow}
      noIndex={pageSEO?.noIndex}
    />
  );
}

export default PageSEO;
