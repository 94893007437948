import { graphql } from 'gatsby';
import React from 'react';
import { BackgroundColorTheme } from '../templates/Page';
import BooksModule, {
  BooksModuleProps,
  getModuleBgColor as getBooksModuleBgColor,
} from './ui/modules/BooksModules';
import CtaModule, {
  CtaModuleProps,
  getModuleBgColor as getCtaModuleBgColor,
} from './ui/modules/CtaModule';
import TextAndImageModule, {
  getModuleBgColor as getTextAndImageModuleBgColor,
  TextAndImageModuleProps,
} from './ui/modules/TextAndImageModule';
import TextModule, {
  getModuleBgColor as getTextModuleBgColor,
  TextModuleProps,
} from './ui/modules/TextModule';
import VideoTestimonialsModule, {
  getModuleBgColor as getVideoTestimonialsModuleBgColor,
  VideoTestimonialsModuleProps,
} from './ui/modules/VideoTestimonialsModule';

export const ModulesFragment = graphql`
  fragment Modules on SanityGtgvBookBooksModuleOrGtgvBookCtaModuleOrGtgvBookTextAndImageModuleOrGtgvBookTextModuleOrGtgvBookVideoTestimonialsModule {
    __typename
    ...BooksModule
    ... on SanityGtgvBookBooksModule {
      moduleId
      disabled
    }
    ...CtaModule
    ... on SanityGtgvBookCtaModule {
      moduleId
      disabled
    }
    ...TextAndImageModule
    ... on SanityGtgvBookTextAndImageModule {
      moduleId
      disabled
    }
    ...TextModule
    ... on SanityGtgvBookTextModule {
      moduleId
      disabled
    }
    ...VideoTestimonialsModule
    ... on SanityGtgvBookVideoTestimonialsModule {
      moduleId
      disabled
    }
  }
`;

export interface ModulesContentProps {
  modules: Array<Module>;
}

export interface CommonModuleProps {
  moduleId?: string;
  disabled?: boolean | null;
  previousModuleBgColor?: BackgroundColorTheme;
}

export type Module =
  | (BooksModuleProps & CommonModuleProps & { __typename: 'SanityGtgvBookBooksModule' })
  | (CtaModuleProps & CommonModuleProps & { __typename: 'SanityGtgvBookCtaModule' })
  | (TextAndImageModuleProps &
      CommonModuleProps & { __typename: 'SanityGtgvBookTextAndImageModule' })
  | (TextModuleProps & CommonModuleProps & { __typename: 'SanityGtgvBookTextModule' })
  | (VideoTestimonialsModuleProps &
      CommonModuleProps & { __typename: 'SanityGtgvBookVideoTestimonialsModule' });

function ModulesContent({ modules }: ModulesContentProps): React.ReactElement {
  const enabledModules = modules.filter(module => !module.disabled);

  return (
    <>
      {enabledModules.map((module, moduleIndex) => {
        const moduleKey = 'module-' + moduleIndex;

        let previousModuleBgColor: BackgroundColorTheme = '#FFFFFF';
        if (moduleIndex > 0) {
          const previousModule = enabledModules[moduleIndex - 1];
          if (previousModule.__typename === 'SanityGtgvBookBooksModule') {
            previousModuleBgColor = getBooksModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityGtgvBookCtaModule') {
            previousModuleBgColor = getCtaModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityGtgvBookTextAndImageModule') {
            previousModuleBgColor = getTextAndImageModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityGtgvBookTextModule') {
            previousModuleBgColor = getTextModuleBgColor(previousModule);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (previousModule.__typename === 'SanityGtgvBookVideoTestimonialsModule') {
            previousModuleBgColor = getVideoTestimonialsModuleBgColor(previousModule);
          } else {
            throw new Error(
              // @ts-expect-error
              'Got unexpected previousModule with typename ' + previousModule.__typename,
            );
          }
        }

        const commonModuleProps: CommonModuleProps = {
          previousModuleBgColor,
        };

        if (module.__typename === 'SanityGtgvBookBooksModule') {
          return <BooksModule key={moduleKey} {...commonModuleProps} {...module}></BooksModule>;
        }
        if (module.__typename === 'SanityGtgvBookCtaModule') {
          return <CtaModule key={moduleKey} {...commonModuleProps} {...module}></CtaModule>;
        }
        if (module.__typename === 'SanityGtgvBookTextAndImageModule') {
          return (
            <TextAndImageModule
              key={moduleKey}
              {...commonModuleProps}
              {...module}
            ></TextAndImageModule>
          );
        }
        if (module.__typename === 'SanityGtgvBookTextModule') {
          return <TextModule key={moduleKey} {...commonModuleProps} {...module}></TextModule>;
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (module.__typename === 'SanityGtgvBookVideoTestimonialsModule') {
          return (
            <VideoTestimonialsModule
              key={moduleKey}
              {...commonModuleProps}
              {...module}
            ></VideoTestimonialsModule>
          );
        }

        // @ts-expect-error
        throw new Error('Got unexpected typename: ' + module.__typename);
      })}
    </>
  );
}

export default ModulesContent;
