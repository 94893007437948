import { Link } from 'gatsby';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { checkIsInternalUrl, getInternalUrlPath } from '../../utils/utils';

export interface SmartLinkProps extends React.HTMLAttributes<HTMLElement> {
  to: string;
  children: React.ReactNode;
  className?: string;
  openInNewTab?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.forwardRef<any, SmartLinkProps>(function SmartLink(
  { openInNewTab, className, children, to, ...restProps },
  ref,
): React.ReactElement {
  if (!openInNewTab && checkIsInternalUrl(to)) {
    if (to.startsWith('#')) {
      return (
        <AnchorLink
          className={className}
          ref={ref}
          href={getInternalUrlPath(to)}
          offset="100"
          {...restProps}
        >
          {children}
        </AnchorLink>
      );
    } else {
      return (
        <Link className={className} ref={ref} to={getInternalUrlPath(to)} {...restProps}>
          {children}
        </Link>
      );
    }
  } else {
    return (
      <a
        className={className}
        ref={ref}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...restProps}
      >
        {children}
      </a>
    );
  }
});
