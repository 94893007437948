import { DataLayer } from '../types/globals';

export function withDataLayer(func: (dataLayer: DataLayer) => void): void {
  if (process.env.NODE_ENV === 'development') {
    const mockDataLayer = [];
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const origPush = mockDataLayer.push;
    mockDataLayer.push = (...data) => {
      console.log('Pushing data to dataLayer: ', ...data);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return origPush.call(mockDataLayer, ...data);
    };
    func(mockDataLayer);
  }

  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    func(window.dataLayer);
  }
}

export function pushOptimizeActivateEvent() {
  return (dataLayer: DataLayer) => {
    dataLayer.push({ event: 'optimize.activate' });
  };
}

export function pushAnalyticsActivateEvent() {
  return (dataLayer: DataLayer) => {
    dataLayer.push({ event: 'analytics.activate' });
  };
}

export function pushFormSubmitEvent(extraData: { type: string }) {
  return (dataLayer: DataLayer) => {
    dataLayer.push({ event: 'form-submit', ...extraData });
  };
}

export function pushClickShareButtonEvent(extraData: { type: string }) {
  return (dataLayer: DataLayer) => {
    dataLayer.push({ event: 'click-share-button', ...extraData });
  };
}

export function pushVideoStartEvent(extraData: { videoUrl: string }) {
  return (dataLayer: DataLayer) => {
    dataLayer.push({ event: 'video-start', ...extraData });
  };
}
