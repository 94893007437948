import React from 'react';

import { clsx } from '../../utils/utils';
import Button, { ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import SmartLink from './SmartLink';

export interface ButtonLinkProps extends ButtonProps {
  to: string;
  className?: string;
  buttonClassName?: string;
}

const ButtonLink = ({
  to,
  className,
  buttonClassName,
  ...restProps
}: ButtonLinkProps): React.ReactElement => {
  return (
    <SmartLink className={clsx(className, styles.link)} to={to} tabIndex={-1}>
      <Button {...restProps} className={buttonClassName}></Button>
    </SmartLink>
  );
};

export default ButtonLink;
