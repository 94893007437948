/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect } from 'react';

import { USE_COOKIES_BANNER } from '../../constants';
import { useGlobalState } from '../../state/globalStateContext';
import { BackgroundColorTheme } from '../../templates/Page';
import {
  preprocessUrlSearchParams,
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import CookiesBanner from './CookiesBanner';
import Footer, { FooterProps } from './Footer';
import Header, { HeaderProps } from './Header';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  pageTheme?: BackgroundColorTheme;
  children: React.ReactNode;
  headerConfig: HeaderProps;
  footerConfig: FooterProps;
  cookiesBannerConfig?: {
    textContent?: React.ReactNode;
  };
}

const Layout = ({
  pageTheme,
  children,
  headerConfig,
  footerConfig,
  cookiesBannerConfig,
}: LayoutProps): React.ReactElement => {
  const isHidden = useActivateGoogleOptimize();
  const { setIsEmbedded } = useGlobalState();

  useStoreUTMParamsOnLoad({ preprocessUrlSearchParams });
  useStoreReferrerOnLoad();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sanityPreviewParam = urlSearchParams.get('_sanityPreview');

    setIsEmbedded(window.parent !== window && !sanityPreviewParam);

    window.dispatchEvent(new CustomEvent('js-loaded'));
  }, []);

  return (
    <div className={styles.container} style={isHidden ? { opacity: 0 } : undefined}>
      <Header {...headerConfig} theme={pageTheme}></Header>
      <div
        role="main"
        className={styles.main}
        data-theme={(pageTheme === 'white' && 'light') || ''}
      >
        {children}
      </div>
      <Footer {...footerConfig}></Footer>
      {USE_COOKIES_BANNER && cookiesBannerConfig?.textContent && (
        <CookiesBanner textContent={cookiesBannerConfig.textContent} />
      )}
    </div>
  );
};

export default Layout;
