import React from 'react';

import { FaArrowRight } from 'react-icons/fa';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import Image from './Image';
import * as styles from './ListItem.module.scss';
import TextLink from './TextLink';

export type ListItemProps = {
  centerAligned?: boolean;
  horizontalLayout?: boolean;
  lessGapBetweenElements?: boolean;
  image?: SanityImageType;
  title: string;
  text: string;
  link?: ButtonLinkType;
  className?: string;
} & (
  | {
      listType: 'withImage';
      imageSize: 'small' | 'large';
      index?: never;
    }
  | {
      listType: 'withNumber';
      imageSize?: never;
      index: number;
    }
  | {
      listType: 'withBullet';
      imageSize?: never;
      index?: never;
    }
  | {
      listType: 'withoutMarkers';
      imageSize?: never;
      index?: never;
    }
);

const ListItem = ({
  centerAligned,
  horizontalLayout,
  lessGapBetweenElements,
  imageSize,
  listType,
  image,
  title,
  text,
  link,
  index,
  className,
}: ListItemProps): React.ReactElement => {
  return (
    <li
      className={clsx(
        className,
        styles.itemContainer,
        centerAligned && styles.centerAligned,
        horizontalLayout && styles.horizontalLayout,
        lessGapBetweenElements && styles.lessGapBetweenElements,
        imageSize === 'large' && styles.withLargerImage,
      )}
    >
      {listType === 'withImage' && image && (
        <div className={clsx(styles.itemImageContainer)}>
          <Image image={image} className={styles.itemImage} />
        </div>
      )}
      {listType === 'withNumber' && (
        <span className={styles.itemNumber}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>
      )}
      {listType === 'withBullet' && (
        <div className={styles.itemBulletContainer}>
          <span className={styles.itemBullet}></span>
        </div>
      )}
      <div className={styles.textContainer}>
        <h3 className={styles.itemTitle}>{title}</h3>
        <p className={styles.itemText}>{text}</p>
        {link && (
          <TextLink to={getUrlFromVersatileLink(link)} className={styles.link}>
            {link.text} <FaArrowRight className={styles.arrow} aria-label="arrow-icon" role="img" />
          </TextLink>
        )}
      </div>
    </li>
  );
};

export default ListItem;
