// extracted by mini-css-extract-plugin
export var columnContainer = "Footer-module--columnContainer--a8b5a";
export var columnTitle = "Footer-module--columnTitle--01b37";
export var columnsContainer = "Footer-module--columnsContainer--9c240";
export var companyDescription = "Footer-module--companyDescription--4bd4b";
export var copyright = "Footer-module--copyright--3b840";
export var divider = "Footer-module--divider--95e90";
export var fixedButton = "Footer-module--fixedButton--22648";
export var footer = "Footer-module--footer--8dcbc";
export var footerContainer = "Footer-module--footerContainer--88188";
export var footerWrapper = "Footer-module--footerWrapper--63158";
export var icon = "Footer-module--icon--b27df";
export var infoContainer = "Footer-module--infoContainer--1a8d8";
export var legalContainer = "Footer-module--legalContainer--6ff6c";
export var line = "Footer-module--line--420dd";
export var link = "Footer-module--link--57e30";
export var linksAndNewsLetterContainer = "Footer-module--linksAndNewsLetterContainer--f241d";
export var logo = "Footer-module--logo--b5f37";
export var logoContainer = "Footer-module--logoContainer--7f664";
export var newsletterContainer = "Footer-module--newsletterContainer--4095a";
export var newsletterInputWrapper = "Footer-module--newsletterInputWrapper--cab54";
export var newsletterWrapper = "Footer-module--newsletterWrapper--f2941";
export var policyLinksContainer = "Footer-module--policyLinksContainer--d8029";
export var simpleFooter = "Footer-module--simpleFooter--fe230";
export var socialMediaContainer = "Footer-module--socialMediaContainer--b2493";
export var visuallyHidden = "Footer-module--visuallyHidden--1ea10";