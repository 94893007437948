/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  PortableTextBlock,
  PortableTextMarkComponentProps,
  PortableTextReactComponents,
  PortableTextTypeComponentProps,
} from '@portabletext/react';
import gsap from 'gsap';
import React from 'react';
import ListItem from './components/ui/ListItem';
import TextLink from './components/ui/TextLink';
import Video from './components/ui/Video';
import * as styles from './portableText.module.scss';
import { PageDocumentType } from './types/types';
import { getPageDocumentUrl } from './utils/sanity';
import { slugify } from './utils/utils';

function getIdFromValue(value: PortableTextBlock): string | undefined {
  return slugify(
    value.children.map(child => (typeof child.text === 'string' ? child.text : '')).join(''),
  );
}

export const components = {
  block: {
    h2: ({ value, children }) => <h2 id={getIdFromValue(value)}>{children}</h2>,
    h3: ({ value, children }) => <h3 id={getIdFromValue(value)}>{children}</h3>,
    h4: ({ value, children }) => <h4 id={getIdFromValue(value)}>{children}</h4>,
    h5: ({ value, children }) => <h5 id={getIdFromValue(value)}>{children}</h5>,
  },
  marks: {
    em: () => <div></div>,
    internalLink: (
      props: PortableTextMarkComponentProps<{
        _type: 'internalLink';
        pageReference: {
          slug: { current: string };
          _type: PageDocumentType;
        };
        anchorLink?: string;
      }>,
    ) => {
      return (
        <TextLink to={getPageDocumentUrl(props.value!.pageReference, props.value!.anchorLink)}>
          {props.children}
        </TextLink>
      );
    },

    externalLink: (
      props: PortableTextMarkComponentProps<{
        _type: 'externalLink';
        url: string;
      }>,
    ) => {
      return <TextLink to={props.value!.url}>{props.children}</TextLink>;
    },
  },
  types: {
    video: (props: PortableTextTypeComponentProps<{ url: string; isAVerticalVideo?: boolean }>) => {
      return <Video url={props.value.url} isAVerticalVideo={props.value.isAVerticalVideo} />;
    },
    listItems: (
      props: PortableTextTypeComponentProps<{
        items: Array<{
          title: string;
          text: string;
        }>;
      }>,
    ) => {
      return (
        <ul>
          {props.value.items.map((item, index) => (
            <ListItem
              listType="withBullet"
              text={item.text}
              horizontalLayout
              title={item.title}
              key={index}
              className={styles.listItem}
            />
          ))}
        </ul>
      );
    },
  },
  list: {
    number: ({ children, value }) =>
      value.level === 2 ? <ol type="a">{children}</ol> : <ol>{children}</ol>,
  },
} satisfies Partial<PortableTextReactComponents>;

export function getPortableTextTimeline(portableTextClass: string) {
  const tl = gsap.timeline();

  // text
  tl.from(`.${portableTextClass}`, {
    y: 100,
    autoAlpha: 0,
    duration: 0.8,
  });

  gsap.context(() => {
    // list items
    const listItemDuration = 0.5;
    const dividerDuration = 2;
    const stagger = 0.2;

    tl.from(`.${styles.listItem}`, {
      y: 10,
      autoAlpha: 0,
      duration: listItemDuration,
      stagger: 2 * stagger,
    });

    tl.from(
      '.' + styles.divider,
      {
        width: 0,
        autoAlpha: 0,
        duration: dividerDuration,
        stagger: 2 * stagger,
      },
      `${stagger + listItemDuration + 0.2}`,
    );
  }, `.${portableTextClass}`);

  return tl;
}
