import '../styles/main.scss';

import React from 'react';

import { graphql } from 'gatsby';
import groq from 'groq';
import ModulesContent, { Module } from '../components/ModulesContent';
import PageSEO from '../components/PageSEO';
import Hero, { HeroProps } from '../components/ui/Hero';
import SanityLayout from '../components/ui/SanityLayout';
import { LocalizedSEOWebsite } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';

export const query = graphql`
  query GetPageById($id: String!) {
    sanityGtgvBookLandingPage(_id: { eq: $id }) {
      title
      slug {
        current
      }
      hero {
        ...Hero
      }
      modules {
        ...Modules
      }
      seo {
        ...SEOWebsite
      }
    }
  }
`;

const groqQuery = groq`{
  "sanityGtgvBookLandingPage": *[_id == $id][0] {
     ...
  }
}`;

export type BackgroundColorTheme = 'white';

interface QueryData {
  sanityGtgvBookLandingPage: {
    title: string;
    slug: {
      current: string;
    };
    hero: HeroProps;
    modules: Array<Module>;
    seo: LocalizedSEOWebsite;
  };
}

const Page = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({ data }): React.ReactElement => {
    const { title, hero, modules, seo } = data.sanityGtgvBookLandingPage;

    return (
      <SanityLayout>
        <PageSEO pageSEO={seo} defaultTitle={seo.title} />
        <Hero {...hero} title={hero.title || title} />
        <ModulesContent modules={modules} />
      </SanityLayout>
    );
  },
);

export default Page;
