// extracted by mini-css-extract-plugin
export var clientBrandContainer = "TextAndImageModule-module--clientBrandContainer--faf58";
export var clientBrandsTitle = "TextAndImageModule-module--clientBrandsTitle--a4c93";
export var clientLogo = "TextAndImageModule-module--clientLogo--65868";
export var clientsBrandsContainer = "TextAndImageModule-module--clientsBrandsContainer--40464";
export var clientsLogosContainer = "TextAndImageModule-module--clientsLogosContainer--0bbe3";
export var contentContainer = "TextAndImageModule-module--contentContainer--aa087";
export var darkBackground = "TextAndImageModule-module--darkBackground--9585b";
export var image = "TextAndImageModule-module--image--e5709";
export var imageContainer = "TextAndImageModule-module--imageContainer--867d4";
export var imageSubtitle = "TextAndImageModule-module--imageSubtitle--548ed";
export var imageSubtitleBackground = "TextAndImageModule-module--imageSubtitleBackground--ceb59";
export var imageSubtitleContainer = "TextAndImageModule-module--imageSubtitleContainer--42c13";
export var mediaContainer = "TextAndImageModule-module--mediaContainer--41498";
export var mediaOnTheRight = "TextAndImageModule-module--mediaOnTheRight--3f0e6";
export var neutralBackground = "TextAndImageModule-module--neutralBackground--0a650";
export var portraitImage = "TextAndImageModule-module--portraitImage--fcf91";
export var text = "TextAndImageModule-module--text--f731e";
export var textContainer = "TextAndImageModule-module--textContainer--83a6a";
export var title = "TextAndImageModule-module--title--de114";