// extracted by mini-css-extract-plugin
export var buttonsContainer = "ModuleLayout-module--buttonsContainer--b9bc3";
export var centeredLayout = "ModuleLayout-module--centeredLayout--7d8ab";
export var centeredTitle = "ModuleLayout-module--centeredTitle--e2514";
export var centeredTitleOnDesktop = "ModuleLayout-module--centeredTitleOnDesktop--cc381";
export var children = "ModuleLayout-module--children--bd07e";
export var childrenWithoutHorizontalPadding = "ModuleLayout-module--childrenWithoutHorizontalPadding--add6f";
export var columnReverseLayout = "ModuleLayout-module--columnReverseLayout--9414e";
export var contentContainer = "ModuleLayout-module--contentContainer--a7903";
export var divider = "ModuleLayout-module--divider--95b5b";
export var moduleContainer = "ModuleLayout-module--moduleContainer--89bc7";
export var rowLayout = "ModuleLayout-module--rowLayout--016fb";
export var rowReverseLayout = "ModuleLayout-module--rowReverseLayout--9f655";
export var text = "ModuleLayout-module--text--658c0";
export var textContainer = "ModuleLayout-module--textContainer--83f26";
export var textWrapper = "ModuleLayout-module--textWrapper--e64bf";
export var title = "ModuleLayout-module--title--51762";