import { PortableText } from '@portabletext/react';
import { graphql } from 'gatsby';
import React from 'react';
import { components } from '../../../portableText';
import { BackgroundColorTheme } from '../../../templates/Page';
import { RawPortableText } from '../../../types/types';
import { CommonModuleProps } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import * as styles from './TextModule.module.scss';

export const TextModuleFragment = graphql`
  fragment TextModule on SanityGtgvBookTextModule {
    title
    _rawText(resolveReferences: { maxDepth: 6 })
  }
`;

export type TextModuleProps = {
  title: string;
  _rawText: RawPortableText;
  className?: string;
};

export function getModuleBgColor(props: TextModuleProps): BackgroundColorTheme {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'white';
}

function TextModule(props: TextModuleProps & CommonModuleProps): React.ReactElement {
  const { title, _rawText, className, moduleId } = props;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
    >
      <div className={styles.columnsContainer}>
        <div className={styles.contentContainer}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <div className={styles.text}>
            <PortableText value={_rawText} components={components} />
          </div>
        </div>
      </div>
    </ModuleLayout>
  );
}

export default TextModule;
