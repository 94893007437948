/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';

import { AUTHOR_NAME, COPYRIGHT_DEFAULT_YEAR } from '../../constants';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { BackgroundColorTheme } from '../../templates/Page';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import * as styles from './Footer.module.scss';
import Form from './Form';
import SmartLink from './SmartLink';
import TextLink from './TextLink';

export type NavigationBlock = {
  title?: string;
  links: Array<ButtonLinkType>;
};

type SocialNetworkIconName = 'facebook' | 'linkedin' | 'whatsapp' | 'instagram';
export type SocialNetworksBlock = {
  title?: string;
  socialNetworks?: Array<{
    iconName: SocialNetworkIconName;
    url?: string;
  }>;
};

type CtaBlock = {
  title?: string;
  ctaButton?: ButtonLinkType;
};

export type NewsletterBlock = {
  title: string;
  buttonText: string;
  emailId: string;
  onNewsletterSubscribe?: (event: React.MouseEvent) => void;
};

export interface FooterProps {
  logoImage: SanityImageType;
  theme?: BackgroundColorTheme;
  companyDescription?: string;
  socialNetworksBlock?: SocialNetworksBlock;
  navigationBlock?: NavigationBlock;
  ctaBlock?: CtaBlock;
  newsletterBlock?: NewsletterBlock;
  policyLinks?: Array<ButtonLinkType>;
}

const Footer = ({
  logoImage,
  theme,
  companyDescription,
  navigationBlock,
  ctaBlock,
  newsletterBlock,
  policyLinks,
  socialNetworksBlock,
}: FooterProps): React.ReactElement => {
  const { t } = useTranslation();
  const [currentYear, setCurrentYear] = useState<number>(COPYRIGHT_DEFAULT_YEAR);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  function renderLogo(logoImage: SanityImageType) {
    return (
      <div className={styles.logoContainer}>
        <img src={logoImage.asset.url} className={styles.logo} height={60} alt="logo" />
      </div>
    );
  }

  function renderCopyright() {
    return (
      <div className={styles.copyright}>
        <div className={styles.line}></div>
        <span>
          © {currentYear}{' '}
          <TextLink withExternalIcon={false} to="https://hookpoint.com">
            {AUTHOR_NAME}
          </TextLink>
        </span>
      </div>
    );
  }

  function renderIcon(title: string) {
    const iconsByTitle = {
      facebook: FaFacebookF,
      linkedin: FaLinkedinIn,
      whatsapp: FaWhatsapp,
      instagram: FaInstagram,
    };
    const Element = iconsByTitle[title];
    return (
      <>
        <Element className={styles.icon} aria-label="social-media-icon" role="img" />
        <div className={styles.visuallyHidden}>
          Visit {AUTHOR_NAME} {title}
        </div>
      </>
    );
  }

  const withNavigationBlock = !!(
    navigationBlock &&
    navigationBlock.links &&
    navigationBlock.links.length > 0
  );

  const withSocialNetworksBlock = !!(
    socialNetworksBlock &&
    socialNetworksBlock.socialNetworks &&
    socialNetworksBlock.socialNetworks?.length > 0
  );

  const withCtaBlock = !!(ctaBlock && ctaBlock.title && ctaBlock.ctaButton);

  const isFooterComplex = !!(
    companyDescription ||
    withNavigationBlock ||
    withCtaBlock ||
    newsletterBlock ||
    (policyLinks && policyLinks.length > 0) ||
    withSocialNetworksBlock
  );

  return (
    <footer className={styles.footer} data-theme={(theme === 'white' && 'light') || ''}>
      {isFooterComplex ? (
        <div className={styles.footerContainer}>
          <div className={styles.footerWrapper}>
            <div className={styles.infoContainer}>
              {renderLogo(logoImage)}
              {companyDescription && (
                <div className={styles.companyDescription}>{companyDescription}</div>
              )}
              {(withNavigationBlock ||
                withSocialNetworksBlock ||
                withCtaBlock ||
                newsletterBlock) && (
                <div className={styles.linksAndNewsLetterContainer}>
                  {(withNavigationBlock || withSocialNetworksBlock || withCtaBlock) && (
                    <div className={styles.columnsContainer}>
                      {withNavigationBlock && (
                        <div className={styles.columnContainer}>
                          <span className={styles.columnTitle}>
                            {navigationBlock.title ||
                              t('footer.navigation.default_title', 'Navigation')}
                          </span>
                          {navigationBlock.links.map((link, i) => (
                            <TextLink
                              to={getUrlFromVersatileLink(link)}
                              key={i}
                              className={styles.link}
                            >
                              {link.text}
                            </TextLink>
                          ))}
                        </div>
                      )}
                      {withSocialNetworksBlock && (
                        <div className={styles.columnContainer}>
                          <span className={styles.columnTitle}>
                            {socialNetworksBlock.title ||
                              t('footer.social_networks.default_title', 'Follow Us')}
                          </span>
                          <div className={styles.socialMediaContainer}>
                            {socialNetworksBlock.socialNetworks?.map(
                              (socialNetwork, i) =>
                                socialNetwork.url && (
                                  <SmartLink key={i} to={socialNetwork.url}>
                                    {renderIcon(socialNetwork.iconName)}
                                  </SmartLink>
                                ),
                            )}
                          </div>
                        </div>
                      )}
                      {withCtaBlock && (
                        <div className={styles.columnContainer}>
                          <span className={styles.columnTitle}>{ctaBlock.title}</span>
                          {ctaBlock.ctaButton && (
                            <ButtonLink
                              to={getUrlFromVersatileLink(ctaBlock.ctaButton)}
                              className={styles.ctaButton}
                              outlined
                            >
                              {ctaBlock?.ctaButton.text}
                            </ButtonLink>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {newsletterBlock && (
                    <div className={styles.newsletterContainer}>
                      <span className={styles.columnTitle}>{newsletterBlock.title}</span>
                      <Form
                        hideLabels
                        withSubmitButtonInsideInput
                        submitButtonText={newsletterBlock.buttonText}
                        thankYouScreen={{ title: 'Thank you' }}
                        fields={[
                          {
                            crmFieldId: newsletterBlock.emailId,
                            id: 'email',
                            fieldType: 'textSingleLine',
                            title: 'Email',
                            withEmailValidation: true,
                          },
                        ]}
                      />
                    </div>
                  )}{' '}
                </div>
              )}
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.legalContainer}>
            {policyLinks && policyLinks.length > 0 && (
              <div className={styles.policyLinksContainer}>
                {policyLinks.map((link, i) => (
                  <TextLink to={getUrlFromVersatileLink(link)} key={i} className={styles.link}>
                    {link.text}
                  </TextLink>
                ))}
              </div>
            )}
            {renderCopyright()}
          </div>
        </div>
      ) : (
        <div className={clsx(styles.footerContainer, styles.simpleFooter)}>{renderCopyright()}</div>
      )}
    </footer>
  );
};

export default Footer;
